import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {encode} from './ConfigCode';
import Typography from '@mui/material/Typography';

const SaveLoadPopUp = ({ config, setConfig }) => {
  const [open, setOpen] = React.useState(false);
  const [hashConfig, setHashConfig] = React.useState(encode(config));

  const handleClickOpen = () => {
    const url = window.location.href.split('?')[0];
    setHashConfig(url + '?code=' + encode(config))
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const onChange = (event) => {
    setHashConfig(event.target.value);
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} startIcon={<SaveIcon />}>
        保存
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogContent>
          <TextField
            id="standard-multiline-flexible"
            label="请选保存代码"
            multiline
            maxRows={10}
            onChange={onChange}
            value={hashConfig}
            variant="standard"
            style={{width:'280px'}}
          />
        </DialogContent>
        <Typography variant="caption" align="center" style={{color: '#BBBBBB'}}>效果图仅供参考，最终成品以实物为准</Typography>
        <DialogActions>
          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SaveLoadPopUp;