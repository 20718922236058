// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';

const ItemPicker = ({ selected, image, text, onClick }) => {
  return (
    <Button variant={selected?'outlined':'text'} style={{ height: 90, width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', padding: 0}} onClick={onClick}>
      <img src={image} alt="" style={{ height: 60, width:'auto', paddingTop: '5px'}}/>
      <Typography variant="caption" component="div" color={"text.secondary"}>
          {text}
      </Typography>
    </Button>
  );
}

export default ItemPicker;